import React from "react";
import Layout from "@app-components/layout/";
import { css } from "@emotion/core";
import Header from "@app-components/ui/header/top_bottom/";
import Testimonial from "@app-components/ui/testimonial";
import Costs from "@media/content-lab/Costs.svg";
import Errors from "@media/content-lab/Errors.svg";
import Time from "@media/content-lab/Time.svg";
import Seat from "@media/aero/seat.svg";
import Stock3 from "@media/aero/s3.jpg";
import Hali from "@media/aero/hali.jpg";
import IFE from "@media/aero/ife_lab.svg";

const ContentLab = () => (
  <Layout page="content">
    <Header
      title={
        <h1 style={{ fontWeight: "300" }}>
          Reduce lab costs through automation
        </h1>
      }
      text="Move your content operations into the cloud and away from a high-touch, high-cost outsourced lab environment."
      secondaryNavigation={{
        icon: IFE,
        base_url: "/content-lab",
        title: "Content Lab",
        links: []
      }}
    />

    <section className="pt4 px2">
      <div className="max-width-4 mx-auto pb4">
        <div className="clearfix mxn3">
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              css={css`
                height: 100px;
              `}
              className="center"
            >
              <img
                src={Errors}
                css={css`
                  width: 150px;
                `}
              />
            </div>
            <div
              css={css`
                font-size: 1.6em;
              `}
            >
              Reduce errors
            </div>
          </div>
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              css={css`
                height: 100px;
              `}
              className="center"
            >
              <img
                src={Costs}
                css={css`
                  width: 150px;
                `}
              />
            </div>
            <div
              css={css`
                font-size: 1.6em;
              `}
            >
              Reduce costs
            </div>
          </div>
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              css={css`
                height: 100px;
              `}
              className="center"
            >
              <img
                src={Time}
                css={css`
                  width: 150px;
                `}
              />
            </div>
            <div
              css={css`
                font-size: 1.6em;
              `}
            >
              Reduce time to market
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      css={css`
        border-bottom: 1px solid #e1e3e4;
        border-top: 1px solid #e1e3e4;
      `}
    >
      <div class="flex split">
        <div class="col-6 flex justify-center items-center">
          <div class="split-inner px4">
            <p
              css={css`
                font-size: 2em !important;
                line-height: 1.25em;
              `}
            >
              The CineSend Media Engine efficiently facilitates content receipt,
              transcoding and QC, and delivery to integrators, all with
              real-time insight and monitoring.
            </p>
          </div>
        </div>
        <div
          class="col-6 bg relative"
          style={{ backgroundImage: `url(${Stock3})` }}
        >
          <div
            css={css`
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 1) 100%
              );
            `}
          />
        </div>
      </div>
    </section>

    <Testimonial
      image={Hali}
      content={`
        CineSend Aero has innovated our content delivery workflow, providing an intuitive and seamless experience for distributors while providing greater transparency and reporting for Air Canada. CineSend's adaptability, ingenuity, and deep understanding of entertainment trends has made them an integral part of Air Canada's in-flight entertainment strategy.
      `}
      name={"Hali Hamilton"}
      credit={"Entertainment and Partnerships, Air Canada"}
    />
  </Layout>
);

ContentLab.propTypes = {};

export default ContentLab;
